<div class="container-fluid px-0">
  <div class="row mt-4" style="position: absolute; width: 100%">
    <div class="col-6 col-md-4 col-lg-6">
      <a href="/">
        <img
          width="1"
          height="1"
          src="/assets/images/vtc.png"
          alt="logo-prinicpal"
          class="logo-principal"
        />
      </a>
      <h1 style="position: absolute; opacity: 0">Sobre Nosotros</h1>
    </div>
    <div class="d-none d-md-block col-md-8 col-lg-6 text-white text-right blur-background" style="text-align: right; padding-right: 20px; ">
      <span style="text-align: right; color: white; text-decoration: none;">
      <a href="/home" style="color: white; text-decoration: none;">
        Inicio
        <span style="margin-left: 15px;">|</span>
      </a>
      <a href="/blog" style="color: white; text-decoration: none; margin-left: 15px">
        Blog
        <span style="margin-left: 15px;">|</span>
      </a>
        <img
        width="1"
        height="1"
        src="/assets/images/whatsapp.png"
        alt="whatsappnicpal"
        style="width:16px; height: auto; margin-left: 30px; display: inline-block;"
      />

      +593 961 459 059
      <img
        width="1"
        height="1"
        src="/assets/images/ecuador.png"
        alt="banner-prinicpal"
        style="width:16px; height: auto; margin-top: -5px; margin-right: 10px"
      />
      +57 312 55 01 225
      <img
      width="1"
      height="1"
      src="/assets/images/colombia.png"
      alt="whatsappnicpal"
      style="width:16px; height: auto; margin-top: -5px"
    /></span>
    </div>
    <div class="col-6 d-block d-md-none text-right">
      <a target="_blank" href="https://api.whatsapp.com/send?phone=593961459059&amp;text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria">
        <img
          width="1"
          height="1"
          src="/assets/images/ws-mobile.png"
          alt="contacto whatsapp"
          style="height: 30px; width: auto; margin-top: 5px;"
          class=""
        />
      </a>
    </div>
  </div>
  <img
    width="1"
    height="1"
    src="/assets/images/politics-main.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-none d-md-block"
  />
  <img
    width="1"
    height="1"
    src="/assets/images/politics-mobile.png"
    alt="banner-prinicpal"
    style="width: 100%; height: auto;"
    class="d-block d-md-none"
  />
</div>

<div class="container">
  <div class="row px-3 px-md-5">
    <div class="about-us-box mt-5 mb-5 px-md-5">
      <p class="about-us">
        <span style="font-weight: 600; font-size: 30px">Política de Manejo de Datos en el Proceso de Solicitud de Visa</span> <br><br>
        <span style="font-weight: 600;  font-size: 22px">Recopilación de Datos Personales</span><br><br> Durante el proceso de solicitud de visa, se recopilan datos personales, previo consentimiento del titular, como:<br><br>
        ● Información de identidad (nombre, fecha de nacimiento, nacionalidad, entre otros).<br>
        ● Información de contacto (dirección, teléfono, correo electrónico).<br>
        ● Datos de pasaporte y documentos oficiales (número de pasaporte, fecha de emisión, fecha de expiración).<br>
        ● Información sobre educación y propósito del viaje (en este caso, estudiar).<br>
        ● Información económica (excepto cuando no sea necesario recopilarla).<br><br>

        <span style="font-weight: 600; font-size: 22px">Finalidad de la Recopilación de Datos</span><br><br> Los datos personales son recopilados con fines específicos y previamente informados al titular:<br><br>
        ● Evaluar la elegibilidad del solicitante para la visa.<br>
        ● Verificar la identidad y los antecedentes del solicitante.<br>
        ● Asegurar que el solicitante cumpla con los requisitos legales y migratorios.<br>
        ● Confirmar la capacidad económica del solicitante para mantenerse durante su estancia.<br><br>

        <span style="font-weight: 600; font-size: 22px">Protección de Datos</span><br><br> Las autoridades consulares y migratorias, así como Visa Travel Center, están obligadas a proteger los datos personales mediante:<br><br>
        ● <span style="font-weight: 600;">Seguridad digital:</span> Uso de sistemas y tecnologías seguras para almacenar la información.<br>
        ● <span style="font-weight: 600;">Acceso restringido:</span> Solo personal autorizado puede acceder a los datos recopilados.<br>
        ● <span style="font-weight: 600;">Confidencialidad:</span> Los datos no serán compartidos ni utilizados fuera de los fines específicos previamente informados.<br><br>

        <span style="font-weight: 600; font-size: 22px">Nuestro Compromiso</span><br><br>

        En Visa Travel Center <span style="font-weight: 600;">cuidamos la privacidad y seguridad de los datos personales</span> que nos confían. Para ello:<br><br>

        ● Utilizamos sistemas digitales seguros y tecnología avanzada para proteger su información.<br>
        ● Garantizamos que solo personal autorizado acceda a los datos.<br>
        ● Designamos a un responsable de protección de datos para supervisar el cumplimiento de nuestras políticas.<br>
        ● Capacitamos de manera constante a nuestro equipo en la gestión de datos personales.<br>
        ● Permitimos el ejercicio de sus derechos, como acceso, rectificación o eliminación de información, según corresponda.<br>
        ● Revisamos y actualizamos continuamente nuestra política de protección de datos para adaptarnos a los cambios legales y tecnológicos.<br><br>

        <span style="font-weight: 600; font-size: 22px">Transferencia de Datos</span><br><br>

        En ciertos casos, y siempre previo consentimiento del titular, los datos pueden ser compartidos con:<br><br>

        ● <span style="font-weight: 600;">Agencias de seguridad o migración</span> para verificación de antecedentes.<br>
        ● <span style="font-weight: 600;">Entidades gubernamentales u organismos</span> encargados de procesar la visa o controlar la entrada al país.<br><br>

        <span style="font-weight: 600; font-size: 22px">Derechos del Solicitante</span><br><br>

        El titular de los datos personales tiene derecho a:<br><br>

        ● <span style="font-weight: 600;">Acceso:</span> Conocer qué datos se están recopilando y con qué propósito.<br>
        ● <span style="font-weight: 600;">Rectificación:</span> Corregir datos incorrectos o desactualizados.<br>
        ● <span style="font-weight: 600;">Supresión:</span> Solicitar la eliminación de datos, siempre que no existan impedimentos legales para ello.<br>
        ● <span style="font-weight: 600;">Oposición:</span> Rechazar el uso de ciertos datos bajo condiciones específicas.<br>
        <br>

        <span style="font-weight: 600; font-size: 22px">Plazo de Conservación de los Datos</span><br><br>

        Los datos personales se conservarán durante el tiempo necesario para procesar la visa y,
        en algunos casos, un tiempo adicional para cumplir con requisitos legales o administrativos.<br><br>

        <span style="font-weight: 600;">Visa Travel Center reafirma su compromiso con la privacidad y la protección de los
        datos personales de los solicitantes</span>, asegurando un manejo transparente y seguro de la
        información proporcionada.
      </p>
    </div>
  </div>
</div>

<footer class="container-fluid footer-bg footer pb-4">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
        <p class="text-white footer-title">Nuestros locales</p>
        <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
        <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
        <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
        <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>

        <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
        <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
      </div>
      <div class="col">
        <p class="footer-title">Ventas</p>
        <p style="margin-bottom: 0px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/ecuador.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p style="margin-top: 5px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/colombia.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
          Sábados de 9 a 13 hs</p>
      </div>
      <div class="col">
        <p class="footer-title">Productos</p>
        <p class="products-list">
          <a href="/home" style="color: white; text-decoration: none">Visas</a>
        </p>
        <p class="products-list">
          <a href="/home" style="color: white; text-decoration: none">Seguros de viajes</a>
          <span></span>
        </p>
        <p class="products-list">
          <a href="/home"style="color: white; text-decoration: none">Paquetes</a>
        </p>
        <a href="/blog" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Blog</span><br /></p>
        </a>
        <a href="/home" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Inicio</span><br /></p>
        </a>
      </div>
      <div class="col">
        <p class="footer-title">Legales</p>
        <p class="text-white" style="font-weight: 400; font-size: 14px">
          Cumplimos con los permisos exigidos. ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP- SEVIFRAL S.A.
        </p>
        <a href="/politicas-privacidad" style="text-decoration: none;">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro">Políticas de privacidad</span><br /></p>
        </a>
      </div>
      <div class="col">
        <div>
          <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
            <div class="mt-3">
              <span class="mt-4">
                <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-fb.png"
                    alt="visatravelcenter facebook"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-ig.png"
                    alt="visatravelcenter Instagram"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-tk.png"
                    alt="visatravelcenter tiktok"
                    style="margin-right: 10px;"
                  />
                </a> </span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3 text-center">
      <p class="text-white footer-title">Nuestros locales</p>
      <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
      <a href="https://maps.app.goo.gl/RE5sV2ZULEf1W6ZX8" target="_blank"  class="mt-2" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Bosques del Salado y Rodrigo Chávez G, <span style="font-weight:  bold;">Guayaquil.</span></a><br>
      <a href="https://maps.app.goo.gl/RwHX8cRz31h8cmeW8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight:  bold;">Quito.</span></a><br>
      <a href="https://maps.app.goo.gl/QA3mrf6WD76MdxaS6" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Presidente Córdova y Benigno Malo, <span style="font-weight:  bold;">Cuenca.</span></a><br>

      <p class="footer-title mt-4" style="font-size: 13px;">Colombia</p>
      <a href="https://maps.app.goo.gl/zNKaKv8NDGap8biU8" target="_blank" style="font-weight: 300; font-size: 13px; color: white; text-decoration: none">Edificio Manhattan center, Oficina: 201#15-17, Chapinero, <span style="font-weight:  bold;">Bogotá.</span></a><br>
    </div>
    <div class="col-12 text-center mt-4">
      <p class="footer-title">Ventas</p>
    <p style="margin-bottom: 0px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/ecuador.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p style="margin-top: 5px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/colombia.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
      Sábados de 9 a 13 hs</p>
  </div>
  <div class="col-12 text-center">
    <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
      <span class="mt-4">
        <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-fb.png"
            alt="visatravelcenter facebook"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-ig.png"
            alt="visatravelcenter Instagram"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-tk.png"
            alt="visatravelcenter tiktok"
            style="margin-right: 10px;"
          />
        </a> </span>
  </div>
  </div>
</footer>

<div class="container-fluid d-none d-lg-block" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px; font-weight: 300">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px; width: 180px !important; height: auto"
      />
      2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.
    </div>
  </div>
</div>

<div class="container-fluid d-lg-none" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center d-none d-lg-block" style="padding-top: 20px; padding-bottom: 20px">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px;"
      />
    </div>
    <div class="col-12 text-white text-center d-block d-lg-none" style="padding-top: 20px; padding-bottom: 20px">
      <img
        width="180"
        height="40"
        class="red-social"
        src="assets/images/vtc.png"
        alt="logo vtc"
        style="margin-right: 20px; height: auto; width: 200px !important"
    />
  </div>
  </div>
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-bottom: 20px; font-weight: 400">
      <span style="font-weight: 300">2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.</span>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" >
    <div class="modal-content" style="border-radius: 24px; padding: 5px 16px">
      <div class="modal-header" style="border-bottom: none;">
        <h3 class="modal-title" id="staticBackdropLabel" style="font-size: 30px; font-family: 'Yeseva One', serif; margin-top: 7px">{{ selectedVisa.name }}</h3>
        <a data-bs-dismiss="modal" aria-label="Close" style="background-color: none; text-decoration: none; cursor: pointer;">x Cerrar</a>
      </div>
      <div class="modal-body">
        {{ selectedVisa.description }}
        @if(currentTab == "visa"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y recibe tu asesoría</p>
        }
        @if(currentTab == "paquetes"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Completa tus datos y reserva tu viaje. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        @if(currentTab == "seguros"){
          <p class="mt-4" style="font-family: 'Yeseva One', serif; font-weight: 500; font-size:19px">Viaja protegido. Completa tus datos ahora y cotiza tu plan. Este incluye:</p>
          <div>
            <ul>
              @for (benefit of selectedVisa.benefits; track benefit) {
                <li>{{benefit}}</li>
              }
            </ul>
          </div>
        }
        <div class="row">
          <div class="col-6">
            <img src="/assets/images/form-name.png"alt="Nombre Completo" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Nombre Completo" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-phone.png"alt="telefono" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="Teléfono (Whatsapp)" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
          <div class="col-6">
            <img src="/assets/images/form-people.png" alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <select class="form-control" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
              <option value="" disabled selected>¿Viajas sólo o en grupo?</option>
              @for (passenger of passengers; track passenger) {
                <option [value]="passenger">Pasajeros: {{ passenger }}</option>
              }

            </select>
          </div>
          <div class="col-6">
            <img src="/assets/images/form-email.png"alt="E-mail" style="width: auto; height: 38px; position:absolute; margin-top: 10px">
            <input type="text" class="form-control" placeholder="E-mail" style="border-radius: 20px; margin-top: 10px; border: 1px solid rgba(19, 107, 192, 0.4); padding-left: 46px">
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            @if(currentTab == "visa"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Asesoría</button>
            }
            @if(currentTab == "paquetes"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Paquete</button>
            }
            @if(currentTab == "seguros"){
              <button class="btn btn-primary mt-4" style="border-radius: 20px; padding: 10px 20px; background-color: #5BA332; border: none">Quiero mi Seguro de Viaje</button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
